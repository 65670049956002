import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { devices } from '@elparking/utils'
import { getBannerShown, setBannerShown } from '../services/banner'
import AppBanner from '../components/AppBanner'
import { useConfig } from '@elparking/components'

const AppBannerContainer = ({ url, image, getBannerShown, setBannerShown, isMobile }) => {
  const tradename = useConfig('tradename')
  const [isBannerShown, setIsBannerShown] = useState(getBannerShown())

  useEffect(() => {
    setBannerShown(isBannerShown)
  }, [isBannerShown, setBannerShown])

  if (isBannerShown) {
    return null
  }
  if (!isMobile()) {
    return null
  }
  return (
      <AppBanner
        url={url}
        image={image}
        tradename={tradename}
        onInstall={() => {
          setIsBannerShown(true)
        }}
      />
  )
}

AppBannerContainer.defaultProps = {
  getBannerShown,
  setBannerShown,
  isMobile: devices.isMobile,
}

AppBannerContainer.propTypes = {
  url: PropTypes.string,
  getBannerShown: PropTypes.func,
  setBannerShown: PropTypes.func,
  image: PropTypes.string,
  isMobile: PropTypes.func,
}

export default AppBannerContainer
