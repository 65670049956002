import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Flex, Box, Copy, Image } from '@elparking/components'

const AppBanner = ({ url, tradename, onInstall }) => (
    <Flex px='16px' py='8px' alignItems='center'>
        <Box flex='0 1 auto' mr='8px' alignSelf='flex-start'>
            <Image
              alt={tradename}
              src='/images/app-icon-maggie.webp'
              width='24px'
            />
        </Box>
        <Box flex='1' mr='16px'>
            <Box>
                <Copy padding='0' size='xxSmall' fontWeight='medium' lineHeight='1.2em'>
                    {tradename}
                </Copy>
            </Box>
            <Copy padding='0' size='xxSmall' fontWeight='regular' lineHeight='1.2em'>
                <FormattedMessage defaultMessage='Descarga la app y aparca con tu móvil' />
            </Copy>
        </Box>
        <Box flex='0 1 auto'>
            <a
              rel='noopener noreferrer'
              href={url}
              onClick={onInstall}
              target='_blank'
              data-event='bannerInstallApp'
            >
                <Copy
                  transform='uppercase'
                  padding='0'
                  color='main'
                  size='xxSmall'
                  fontWeight='medium'
                >
                    <FormattedMessage defaultMessage='Instalar' />
                </Copy>
            </a>
        </Box>
    </Flex>
)

AppBanner.propTypes = {
    url: PropTypes.string.isRequired,
    tradename: PropTypes.string.isRequired,
    onInstall: PropTypes.func.isRequired,
}

export default AppBanner
