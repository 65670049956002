/* istanbul ignore file */
import { hot } from 'react-hot-loader/root'
import React from 'react'
import { IntlProvider } from 'react-intl'

import { messages } from 'commons/js/messages'
import { defaultLocale, currentLocale } from 'commons/js/locale'
import AppBanner from './AppBanner/containers/AppBannerContainer'

import { EPProvider } from '@elparking/components'

import theme from './theme'
import convertConstantToEPProviderFormat from 'commons/js/util/coverterGlobalConfig'

const App = (props) => {
    const config = convertConstantToEPProviderFormat()
    return (
        <IntlProvider
          locale={currentLocale}
          defaultLocale={defaultLocale}
          messages={messages[currentLocale]}
        >
            <EPProvider theme={theme} {...config}>
                <AppBanner {...props} />
            </EPProvider>
        </IntlProvider>
    )
}

export default hot(App)
