/* istanbul ignore file */
import { localStorage } from '@elparking/utils'

const APP_BANNER_KEY = 'ElParking:app-noticer'
const APP_BANNER_NOT_SEEN = '1'
const APP_BANNER_SEEN = '2'

export function getBannerShown () {
  return localStorage.get(APP_BANNER_KEY) === APP_BANNER_SEEN
}

export function setBannerShown (isShown) {
  localStorage.save(
    APP_BANNER_KEY,
    isShown ? APP_BANNER_SEEN : APP_BANNER_NOT_SEEN
  )
}
