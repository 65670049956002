import App from './App'
import render from 'commons/js/util/render'
import { init } from 'commons/js/util/reporter'
import { FLAVOR } from 'commons/js/constants'

init()

const element = document.querySelector('.js-app-banner')
const dataset = element.dataset
const dataProps = {
    url: dataset.firebaseUrl,
    image: FLAVOR ? '/images/app-icon-' + FLAVOR + '.webp' : null,
}

render({ App, element, props: { ...dataProps } })
