import { init as initReport, ERROR_LEVEL } from '@elparking/reporter'
import * as Sentry from '@sentry/browser'
import {
    getConstant,
    LOCAL_ENVIRONMENT,
    TEST_ENVIRONMENT,
} from '../constants'

const developmentEnvironments = [
    LOCAL_ENVIRONMENT,
    TEST_ENVIRONMENT,
]

function active () {
    return !developmentEnvironments.includes(getConstant('YII_ENV'))
}

window.DEBUG = DEBUG

const initReportFn = (params) => {
    Sentry.init(params)
}

const captureException = () => {
    // TODO
}
const captureMessage = () => {
    // TODO
}

function init (level = ERROR_LEVEL, initReportCallbackFn = initReportFn, captureExceptionFn = captureException, captureMessageFn = captureMessage) {
    const isActive = active()
    if (isActive) {
        initReport(
            level,
            {
                environment: getConstant('YII_ENV'),
                project: 'elParkingWeb',
            },
            initReportCallbackFn,
            captureExceptionFn,
            captureMessageFn
        )
    }
}

export {
    init,
}
